/**
 * Do not edit directly
 * Generated on Wed, 13 Nov 2024 13:05:11 GMT
 */

export const ZW = "/assets/flags/ZW.png";
export const ZM = "/assets/flags/ZM.png";
export const ZA = "/assets/flags/ZA.png";
export const YT = "/assets/flags/YT.png";
export const YE = "/assets/flags/YE.png";
export const XK = "/assets/flags/XK.png";
export const WS = "/assets/flags/WS.png";
export const WF = "/assets/flags/WF.png";
export const VU = "/assets/flags/VU.png";
export const VN = "/assets/flags/VN.png";
export const VI = "/assets/flags/VI.png";
export const VG = "/assets/flags/VG.png";
export const VE = "/assets/flags/VE.png";
export const VC = "/assets/flags/VC.png";
export const VA = "/assets/flags/VA.png";
export const UZ = "/assets/flags/UZ.png";
export const UY = "/assets/flags/UY.png";
export const US = "/assets/flags/US.png";
export const UN = "/assets/flags/UN.png";
export const UM = "/assets/flags/UM.png";
export const UG = "/assets/flags/UG.png";
export const UA = "/assets/flags/UA.png";
export const TZ = "/assets/flags/TZ.png";
export const TW = "/assets/flags/TW.png";
export const TV = "/assets/flags/TV.png";
export const TT = "/assets/flags/TT.png";
export const TR = "/assets/flags/TR.png";
export const TO = "/assets/flags/TO.png";
export const TN = "/assets/flags/TN.png";
export const TM = "/assets/flags/TM.png";
export const TL = "/assets/flags/TL.png";
export const TK = "/assets/flags/TK.png";
export const TJ = "/assets/flags/TJ.png";
export const TH = "/assets/flags/TH.png";
export const TG = "/assets/flags/TG.png";
export const TF = "/assets/flags/TF.png";
export const TD = "/assets/flags/TD.png";
export const TC = "/assets/flags/TC.png";
export const SZ = "/assets/flags/SZ.png";
export const SY = "/assets/flags/SY.png";
export const SX = "/assets/flags/SX.png";
export const SV = "/assets/flags/SV.png";
export const ST = "/assets/flags/ST.png";
export const SS = "/assets/flags/SS.png";
export const SR = "/assets/flags/SR.png";
export const SO = "/assets/flags/SO.png";
export const SN = "/assets/flags/SN.png";
export const SM = "/assets/flags/SM.png";
export const SL = "/assets/flags/SL.png";
export const SK = "/assets/flags/SK.png";
export const SI = "/assets/flags/SI.png";
export const SG = "/assets/flags/SG.png";
export const SE = "/assets/flags/SE.png";
export const SD = "/assets/flags/SD.png";
export const SC = "/assets/flags/SC.png";
export const SB = "/assets/flags/SB.png";
export const SA = "/assets/flags/SA.png";
export const RW = "/assets/flags/RW.png";
export const RU = "/assets/flags/RU.png";
export const RS = "/assets/flags/RS.png";
export const RO = "/assets/flags/RO.png";
export const RE = "/assets/flags/RE.png";
export const QA = "/assets/flags/QA.png";
export const PY = "/assets/flags/PY.png";
export const PW = "/assets/flags/PW.png";
export const PT = "/assets/flags/PT.png";
export const PS = "/assets/flags/PS.png";
export const PR = "/assets/flags/PR.png";
export const PN = "/assets/flags/PN.png";
export const PM = "/assets/flags/PM.png";
export const PL = "/assets/flags/PL.png";
export const PK = "/assets/flags/PK.png";
export const PH = "/assets/flags/PH.png";
export const PG = "/assets/flags/PG.png";
export const PF = "/assets/flags/PF.png";
export const PE = "/assets/flags/PE.png";
export const PA = "/assets/flags/PA.png";
export const OM = "/assets/flags/OM.png";
export const NZ = "/assets/flags/NZ.png";
export const NU = "/assets/flags/NU.png";
export const NR = "/assets/flags/NR.png";
export const NP = "/assets/flags/NP.png";
export const NL = "/assets/flags/NL.png";
export const NI = "/assets/flags/NI.png";
export const NG = "/assets/flags/NG.png";
export const NF = "/assets/flags/NF.png";
export const NE = "/assets/flags/NE.png";
export const NC = "/assets/flags/NC.png";
export const NA = "/assets/flags/NA.png";
export const MZ = "/assets/flags/MZ.png";
export const MY = "/assets/flags/MY.png";
export const MX = "/assets/flags/MX.png";
export const MW = "/assets/flags/MW.png";
export const MV = "/assets/flags/MV.png";
export const MU = "/assets/flags/MU.png";
export const MT = "/assets/flags/MT.png";
export const MS = "/assets/flags/MS.png";
export const MR = "/assets/flags/MR.png";
export const MQ = "/assets/flags/MQ.png";
export const MP = "/assets/flags/MP.png";
export const MO = "/assets/flags/MO.png";
export const MN = "/assets/flags/MN.png";
export const MM = "/assets/flags/MM.png";
export const ML = "/assets/flags/ML.png";
export const MK = "/assets/flags/MK.png";
export const MH = "/assets/flags/MH.png";
export const MG = "/assets/flags/MG.png";
export const ME = "/assets/flags/ME.png";
export const MD = "/assets/flags/MD.png";
export const MC = "/assets/flags/MC.png";
export const MA = "/assets/flags/MA.png";
export const LY = "/assets/flags/LY.png";
export const LV = "/assets/flags/LV.png";
export const LU = "/assets/flags/LU.png";
export const LT = "/assets/flags/LT.png";
export const LS = "/assets/flags/LS.png";
export const LR = "/assets/flags/LR.png";
export const LK = "/assets/flags/LK.png";
export const LI = "/assets/flags/LI.png";
export const LC = "/assets/flags/LC.png";
export const LB = "/assets/flags/LB.png";
export const LA = "/assets/flags/LA.png";
export const KZ = "/assets/flags/KZ.png";
export const KY = "/assets/flags/KY.png";
export const KW = "/assets/flags/KW.png";
export const KR = "/assets/flags/KR.png";
export const KP = "/assets/flags/KP.png";
export const KN = "/assets/flags/KN.png";
export const KM = "/assets/flags/KM.png";
export const KI = "/assets/flags/KI.png";
export const KH = "/assets/flags/KH.png";
export const KG = "/assets/flags/KG.png";
export const KE = "/assets/flags/KE.png";
export const JP = "/assets/flags/JP.png";
export const JO = "/assets/flags/JO.png";
export const JM = "/assets/flags/JM.png";
export const JE = "/assets/flags/JE.png";
export const IT = "/assets/flags/IT.png";
export const IS = "/assets/flags/IS.png";
export const IR = "/assets/flags/IR.png";
export const IQ = "/assets/flags/IQ.png";
export const IO = "/assets/flags/IO.png";
export const IN = "/assets/flags/IN.png";
export const IM = "/assets/flags/IM.png";
export const IL = "/assets/flags/IL.png";
export const IE = "/assets/flags/IE.png";
export const ID = "/assets/flags/ID.png";
export const IC = "/assets/flags/IC.png";
export const HU = "/assets/flags/HU.png";
export const HT = "/assets/flags/HT.png";
export const HR = "/assets/flags/HR.png";
export const HN = "/assets/flags/HN.png";
export const HK = "/assets/flags/HK.png";
export const GLOBE_US = "/assets/flags/Globe-US.png";
export const GLOBE_EU = "/assets/flags/Globe-EU.png";
export const GLOBE_AS = "/assets/flags/Globe-AS.png";
export const GY = "/assets/flags/GY.png";
export const GW = "/assets/flags/GW.png";
export const GU = "/assets/flags/GU.png";
export const GT = "/assets/flags/GT.png";
export const GS = "/assets/flags/GS.png";
export const GR = "/assets/flags/GR.png";
export const GQ = "/assets/flags/GQ.png";
export const GP = "/assets/flags/GP.png";
export const GN = "/assets/flags/GN.png";
export const GM = "/assets/flags/GM.png";
export const GL = "/assets/flags/GL.png";
export const GI = "/assets/flags/GI.png";
export const GH = "/assets/flags/GH.png";
export const GG = "/assets/flags/GG.png";
export const GF = "/assets/flags/GF.png";
export const GE = "/assets/flags/GE.png";
export const GD = "/assets/flags/GD.png";
export const GB = "/assets/flags/GB.png";
export const GB_WLS = "/assets/flags/GB-WLS.png";
export const GB_STL = "/assets/flags/GB-STL.png";
export const GB_ENG = "/assets/flags/GB-ENG.png";
export const GA = "/assets/flags/GA.png";
export const FR = "/assets/flags/FR.png";
export const FO = "/assets/flags/FO.png";
export const FM = "/assets/flags/FM.png";
export const FK = "/assets/flags/FK.png";
export const FJ = "/assets/flags/FJ.png";
export const FI = "/assets/flags/FI.png";
export const EU = "/assets/flags/EU.png";
export const ET = "/assets/flags/ET.png";
export const ES = "/assets/flags/ES.png";
export const ER = "/assets/flags/ER.png";
export const EH = "/assets/flags/EH.png";
export const EG = "/assets/flags/EG.png";
export const EE = "/assets/flags/EE.png";
export const EC = "/assets/flags/EC.png";
export const EA = "/assets/flags/EA.png";
export const DZ = "/assets/flags/DZ.png";
export const DO = "/assets/flags/DO.png";
export const DM = "/assets/flags/DM.png";
export const DK = "/assets/flags/DK.png";
export const DJ = "/assets/flags/DJ.png";
export const DE = "/assets/flags/DE.png";
export const CZ = "/assets/flags/CZ.png";
export const CY = "/assets/flags/CY.png";
export const CX = "/assets/flags/CX.png";
export const CW = "/assets/flags/CW.png";
export const CV = "/assets/flags/CV.png";
export const CU = "/assets/flags/CU.png";
export const CR = "/assets/flags/CR.png";
export const CP = "/assets/flags/CP.png";
export const CO = "/assets/flags/CO.png";
export const CN = "/assets/flags/CN.png";
export const CM = "/assets/flags/CM.png";
export const CL = "/assets/flags/CL.png";
export const CI = "/assets/flags/CI.png";
export const CH = "/assets/flags/CH.png";
export const CG = "/assets/flags/CG.png";
export const CF = "/assets/flags/CF.png";
export const CD = "/assets/flags/CD.png";
export const CC = "/assets/flags/CC.png";
export const CA = "/assets/flags/CA.png";
export const BZ = "/assets/flags/BZ.png";
export const BY = "/assets/flags/BY.png";
export const BW = "/assets/flags/BW.png";
export const BV = "/assets/flags/BV.png";
export const BT = "/assets/flags/BT.png";
export const BS = "/assets/flags/BS.png";
export const BR = "/assets/flags/BR.png";
export const BQ = "/assets/flags/BQ.png";
export const BO = "/assets/flags/BO.png";
export const BN = "/assets/flags/BN.png";
export const BM = "/assets/flags/BM.png";
export const BL = "/assets/flags/BL.png";
export const BJ = "/assets/flags/BJ.png";
export const BI = "/assets/flags/BI.png";
export const BH = "/assets/flags/BH.png";
export const BG = "/assets/flags/BG.png";
export const BF = "/assets/flags/BF.png";
export const BE = "/assets/flags/BE.png";
export const BD = "/assets/flags/BD.png";
export const BB = "/assets/flags/BB.png";
export const BA = "/assets/flags/BA.png";
export const AZ = "/assets/flags/AZ.png";
export const AX = "/assets/flags/AX.png";
export const AW = "/assets/flags/AW.png";
export const AU = "/assets/flags/AU.png";
export const AT = "/assets/flags/AT.png";
export const AS = "/assets/flags/AS.png";
export const AR = "/assets/flags/AR.png";
export const AQ = "/assets/flags/AQ.png";
export const AO = "/assets/flags/AO.png";
export const AM = "/assets/flags/AM.png";
export const AL = "/assets/flags/AL.png";
export const AI = "/assets/flags/AI.png";
export const AG = "/assets/flags/AG.png";
export const AF = "/assets/flags/AF.png";
export const AE = "/assets/flags/AE.png";
export const AD = "/assets/flags/AD.png";
